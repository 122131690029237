













































































































































































































































































































































































































































.cu_btn_add {
  border: none;
  background: none;
  display: block;
  &:hover {
    background-color: #ecf5ff;
    color: #66b1ff;
  }
}
.working_together_wrapper {
  .team_routes_wrapper {
    display: flex;
    gap: 10px;
    .back_link {
      color: #777;
      background-color: #eee;
      padding: 8px 10px;
      display: block;
      width: fit-content;
      margin: 10px 0;
      border-radius: 8px;
      transition: 0.3s;
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
  }
  // Start Header And All Sections Style
  .card-title {
    color: #777;
    font-size: 17px !important;
  }
  .importance_wrapper {
    color: #777;
    margin-bottom: 3rem;
    h4 {
      margin-top: 10px;
    }
    ol {
      li {
        margin-bottom: 10px;
        line-height: 1.7;
      }
    }
  }
  .dialog_footer {
    display: flex;
    justify-content: end;
    align-items: center;
    @media (min-width: 320px) and (max-width: 991px) {
      justify-content: center;
    }
  }
}
// // End Collapse
::v-deep .el-dialog {
  @media (min-width: 320px) and (max-width: 480px) {
    width: 90% !important;
  }
  @media (min-width: 480px) and (max-width: 767px) {
    width: 70% !important;
  }
}
.dialog_footer {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (min-width: 320px) and (max-width: 991px) {
    justify-content: center;
  }
}
